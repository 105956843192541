import { render, staticRenderFns } from "./UrlConfig.vue?vue&type=template&id=be7a4268&scoped=true"
import script from "./UrlConfig.vue?vue&type=script&lang=js"
export * from "./UrlConfig.vue?vue&type=script&lang=js"
import style0 from "./UrlConfig.vue?vue&type=style&index=0&id=be7a4268&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be7a4268",
  null
  
)

export default component.exports