<template>
  <div>
    <div v-if="showTitle">
      <label class="w-100 d-flex align-items-center">
        ラベル
        <required-mark v-if="requiredLabel" />
      </label>
      <div class="w-100">
        <input
          :name="name + '_label'"
          placeholder="ラベルを入力してください"
          type="text"
          maxlength="12"
          v-model.trim="value.label"
          class="w-100 form-control"
          @keyup="changeValue"
          v-validate="{ required: requiredLabel && showTitle }"
          data-vv-as="ラベル"
        />
        <error-message :message="errors.first(name + '_label')"></error-message>
      </div>
    </div>

    <div class="mt-2">
      <label class="d-flex align-items-center">URL<required-mark /></label>
      <input
        :name="name + '_value'"
        type="text"
        maxlength="1000"
        v-model.trim="value.uri"
        placeholder="https://example.com"
        class="w-100 form-control"
        @keyup="changeValue"
        v-validate="{ url: { require_protocol: true }, required: true }"
        data-vv-as="URL"
      />
      <!-- <input
        type="hidden"
        v-model="value.uri"
        v-validate="{ required: true, url: { require_protocol: true } }"
        :name="name + '_value'"
      /> -->
      <error-message :message="errors.first(name + '_value')"></error-message>
    </div>
  </div>
</template>
<script>
import Util from '@/core/util';

export default {
  props: {
    value: Object,
    name: {
      type: String,
      default: 'action'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    requiredLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      data: this.value
    };
  },
  inject: ['parentValidator'],
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    changeValue() {
      this.value.linkUri = this.value.uri;
      this.$emit('input', this.value);
    },

    getRegexUrl() {
      return Util.regexUrl();
    }
  }
};
</script>

<style lang="scss" scoped></style>
