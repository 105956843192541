<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <folder-left
          type="tag"
          :data="folders"
          :isPc="isPc"
          :selectedFolder="selectedFolderIndex"
          @changeSelectedFolder="onFolderChanged"
          @submitUpdateFolder="submitUpdateFolder"
          @submitCreateFolder="submitCreateFolder"
        />
        <div class="flex-grow-1 folder-right">
          <div class="d-flex">
            <div class="btn btn-primary" @click="addTag"><i class="uil-plus"></i> 新規登録</div>
            <div class="ml-auto d-flex">
              <div class="input-group app-search">
                <input
                    type="text"
                    class="form-control dropdown-toggle fw-250"
                    placeholder="検索..."
                    v-model="queryParams.name_cont"
                />
                <span class="mdi mdi-magnify search-icon"></span>
                <div class="input-group-append">
                  <div class="btn btn-primary" @click="search()">検索</div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive mt-2">
            <table class="table mb-0">
              <thead class="thead-light">
                <tr>
                  <th>タグ名</th>
                  <th>メンバー数</th>
                  <th>作成日</th>
                </tr>
              </thead>
              <tbody v-show="curFolder">
                <tr v-show="showTagInput">
                  <td class="mw-200">
                    <div>
                      <div class="input-group newgroup-inputs">
                        <input
                          type="text"
                          placeholder="タグ名"
                          class="form-control"
                          @click.stop
                          v-model.trim="tagData.name"
                          ref="tagName"
                          name="tag_name"
                          maxlength="33"
                          v-validate="'required|max:32'"
                          data-vv-as="タグ名"
                        />
                        <span class="input-group-btn">
                          <div role="button" class="btn btn-light" @click="submitCreateTag" ref="buttonAddTag">
                            決定
                          </div>
                        </span>
                      </div>
                      <error-message
                        :message="errors.first('tag_name')"
                        v-show="errors.first('tag_name')"
                      ></error-message>
                    </div>
                  </td>
                  <td><span class="mt-2">0人</span></td>
                  <td>{{ getCreatedAt() }}</td>
                </tr>
                <tag-item
                  v-for="(item, index) in tags"
                  :tag="item"
                  :key="index"
                  @deleteTag="curTag = item"
                  @editTag="submitUpdateTag"
                  @showFriends="curTag = $event"
                />
              </tbody>
            </table>
            <div class="d-flex justify-content-center mt-3 text-center">
              <b-pagination
                  v-if="totalRows > perPage"
                  v-model="queryParams.page"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @change="loadPage"
                  aria-controls="my-table"
              ></b-pagination>
            </div>
            <div v-if="!loading && totalRows === 0" class="mt-5 text-md text-center">
              <b>登録したタグはありません。</b>
            </div>
          </div>
        </div>
      </div>

      <modal-confirm
        title="このフォルダーを削除してもよろしいですか？"
        id="modalDeleteFolder"
        type="delete"
        @confirm="submitDeleteFolder"
      >
        <template v-slot:content v-if="curFolder">
          <div>
            フォルダー名：<b>{{ curFolder.name }}</b>
          </div>
        </template>
      </modal-confirm>

      <modal-confirm
        title="このタグを削除してもよろしいですか？"
        type="delete"
        id="modalConfirmDelTag"
        @confirm="submitDeleteTag"
      >
        <template v-slot:content v-if="curFolder && curTag">
          <div>
            フォルダー名：<b>{{ curFolder.name }}</b>
          </div>
          <div class="mt-2">
            タグ名：<b>{{ curTag.name }}</b>
          </div>
        </template>
      </modal-confirm>

      <modal-tag-friends :tag="curTag"></modal-tag-friends>
    </div>
    <loading-indicator :loading="loading"></loading-indicator>
  </div>
</template>
<script>
import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
import Util from '@/core/util';
import moment from 'moment';

export default {
  data() {
    return {
      loading: true,
      tagData: {
        id: null,
        name: null,
        folder_id: null
      },
      selectedFolderIndex: 0,
      showFolderInput: false,
      showTagInput: false,
      isPc: true,
      curTag: null,
      friends: [],
      isEnter: true,
      queryParams: {}
    };
  },

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  async beforeMount() {
    await this.getFolders();
    const folderId = Number.parseInt(Util.getParamFromUrl('folder_id'));
    const index = folderId > 0 ? _.findIndex(this.folders, _ => _.id === folderId) : 0;
    await this.onFolderChanged(index > 0 ? index : 0);
    this.loading = false;
  },

  computed: {
    ...mapGetters('tag', ['getQueryParams']),
    ...mapState('tag', {
      folders: state => state.folders,
      tags: state => state.tags,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolderIndex];
    },
  },
  methods: {
    ...mapMutations('tag', ['setQueryParams']),
    ...mapActions('tag', [
      'createFolder',
      'updateFolder',
      'deleteFolder',
      'addNewTag',
      'createTag',
      'updateTag',
      'deleteTag',
      'getTags',
      'getFolders',
    ]),

    forceRerender() {
      this.contentKey++;
    },

    backToFolder() {
      this.isPc = false;
    },

    async onFolderChanged(index) {
      this.selectedFolderIndex = index;
      this.showTagInput = false;
      this.isPc = true;
      this.queryParams.page = 1;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        await this.loadPage();
      }
    },

    addMoreFolder() {
      this.showFolderInput = !this.showFolderInput;
      if (this.showTagInput) {
        this.showTagInput = false;
      }
      this.folderData.name = '';
    },

    async submitCreateFolder(value) {
      const response = await this.createFolder(value);
      if (response) {
        window.toastr.success('フォルダーの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
    },

    async submitUpdateFolder(value) {
      const response = await this.updateFolder(value);
      if (response) {
        window.toastr.success('フォルダーの変更は完了しました。');
      } else {
        window.toastr.error('フォルダーの変更は失敗しました。');
      }
    },

    async submitDeleteFolder() {
      const response = await this.deleteFolder(this.curFolder.id);
      if (response) {
        window.toastr.success('フォルダーの削除は完了しました。');
        this.onFolderChanged(0);
      } else {
        window.toastr.error('フォルダーの削除は完了しました。');
      }
    },

    addTag() {
      this.showTagInput = !this.showTagInput;
      if (this.showFolderInput) {
        this.showFolderInput = false;
      }
      this.tagData.name = '';
    },

    async submitDeleteTag() {
      const response = await this.deleteTag(this.curTag.id);
      if (response) {
        Util.showSuccessThenRedirect('タグの削除は完了しました。', window.location.href);
      }
    },

    async submitCreateTag() {
      const passed = await this.$validator.validateAll();
      if (!passed) return;
      const payload = {
        folder_id: this.curFolder.id,
        name: this.tagData.name
      };
      const response = await this.createTag(payload);
      if (response) {
        window.toastr.success('タグの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
      this.resetTagInput();
    },

    async submitUpdateTag(tag) {
      this.showFolderInput = false;
      this.showTagInput = false;
      const response = await this.updateTag(tag);
      if (response) {
        window.toastr.success('タグの変更は完了しました。');
      } else {
        window.toastr.error('タグの変更は失敗しました。');
      }
      this.forceRerender();
    },

    resetTagInput() {
      this.showTagInput = false;
      this.tagData = {
        id: null,
        name: null,
        folder_id: null
      };
    },

    getCreatedAt(item) {
      return moment().format('YYYY.MM.DD');
    },

    showNewTagInput(e) {
      if (!this.isEnter) {
        this.isEnter = true;
        return;
      }
      this.$refs.buttonAddTag.click();
    },

    onReceiveCreateTagResponse(response) {
      if (response && response.id) {
        window.toastr.success('フォルダーまたはタグの作成は完了しました。');
      } else {
        window.toastr.error(response.message);
      }
    },

    async search() {
      this.queryParams.page = 1;
      await this.loadPage(true);
    },

    async loadPage(isLoadFolder = false) {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        this.loading = true;
        if (isLoadFolder === true) {
          await this.getFolders({ is_filter: true });
        }
        await this.getTags({ q: _.omit(this.queryParams, 'page') });
        this.forceRerender();
        this.loading = false;
      });
    }
  }
};
</script>
