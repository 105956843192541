<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <reorder-folder-left
          :type="'template_message'"
          :data="folders"
          :isPc="isPc"
          :selectedFolder="selectedFolderIndex"
          @changeSelectedFolder="onSelectedFolderChanged"
          @submitUpdateFolder="submitUpdateFolder"
          @submitCreateFolder="submitCreateFolder"
          @reorder="onDragFolderEnd"
        />
        <div class="flex-grow-1" :key="contentKey">
          <a
            v-if="folders && folders.length && curFolder"
            :href="`${rootPath}/user/templates/new?folder_id=${curFolder.id}`"
            class="btn btn-primary"
          >
            <i class="uil-plus"></i> 新規作成
          </a>
          <div class="mt-2" v-if="curFolder">
            <table class="table table-centered mb-0">
              <thead class="thead-light">
                <tr>
                  <th></th>
                  <th>テンプレート名</th>
                  <th>メッセージ数</th>
                  <th>操作</th>
                  <th>フォルダー</th>
                  <th></th>
                </tr>
              </thead>
              <draggable
                tag="tbody"
                v-model="templatesCopy"
                @end="onDragTemplateEnd"
                drag-class="dragging-item"
                ghost-class="destination-item"
              >
                <tr
                  v-for="(template, index) in templatesCopy"
                  :key="template.id"
                  class="draggable-row"
                >
                  <td class="drag-icon-cell">
                    <i class="mdi mdi-drag text-secondary"></i>
                  </td>
                  <td>
                    <div class="fw-300 text-truncate">{{ template.name }}</div>
                  </td>
                  <td class="fw-150 d-flex justify-content-center align-items-center template-message-count-wrapper">
                    <span class="template-message-count">{{ template.template_messages_count }}</span>
                    <div
                      class="btn btn-sm btn-light my-1"
                      data-toggle="modal"
                      data-target="#modalTemplatePreview"
                      @click="curTemplateIndex = index"
                    >
                      プレビュー
                    </div>
                  </td>
                  <td>
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-light btn-sm dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        操作 <span class="caret"></span>
                      </button>
                      <div class="dropdown-menu">
                        <a
                          role="button"
                          class="dropdown-item"
                          :href="`${rootPath}/user/templates/${template.id}/edit`"
                          target="_blank"
                          >テンプレートを編集</a
                        >
                        <a
                          role="button"
                          class="dropdown-item"
                          data-toggle="modal"
                          data-target="#modalCopyTemplate"
                          @click="curTemplateIndex = index"
                          >テンプレートをコピー</a
                        >
                        <a
                          role="button"
                          class="dropdown-item"
                          data-toggle="modal"
                          data-target="#modalDeleteTemplate"
                          @click="curTemplateIndex = index"
                          >テンプレートを削除</a
                        >
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div class="mxw-200 text-truncate">{{ curFolder.name }}</div>
                    </div>
                    <div class="text-sm">{{ formattedDate(template.created_at) }}</div>
                  </td>
                  <td>
                    <div class="actions ml-auto mr-2">
                      <div @click.stop="moveTemplateUp(index)" :disabled="index === 0" class="btn btn-light btn-sm action-button">
                        <i class="dripicons-chevron-up"></i>
                      </div>
                      <div
                        @click.stop="moveTemplateDown(index)"
                        class="btn btn-light btn-sm action-button"
                      >
                        <i class="dripicons-chevron-down"></i>
                      </div>
                    </div>
                  </td>
                </tr>
              </draggable>
            </table>
            <div class="d-flex justify-content-center mt-3 text-center">
              <b-pagination
                  v-if="totalRows > perPage"
                  v-model="queryParams.page"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @change="loadPage"
                  aria-controls="my-table"
              ></b-pagination>
            </div>
            <div class="text-center mt-5" v-if="templatesCopy.length === 0">
              <b>テンプレートはありません。</b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-indicator :loading="loading"></loading-indicator>

    <!-- START: Delete folder modal -->
    <modal-confirm
      title="このフォルダーを削除してもよろしいですか？"
      id="modalDeleteFolder"
      type="delete"
      @confirm="submitDeleteFolder"
    >
      <template v-slot:content v-if="curFolder">
        <span>フォルダー名：{{ curFolder.name }}</span>
      </template>
    </modal-confirm>
    <!-- END: Delete folder modal -->

    <!-- START: Delete template modal -->
    <modal-confirm
      title="このテンプレートを削除してもよろしいですか？"
      id="modalDeleteTemplate"
      type="delete"
      @confirm="submitDeleteTemplate"
    >
      <template v-slot:content>
        <div v-if="curTemplate">
          テンプレート名：<b>{{ curTemplate.name }}</b>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Delete template modal -->

    <!-- START: Copy template modal -->
    <modal-confirm
      title="このテンプレートをコピーしてもよろしいですか？"
      id="modalCopyTemplate"
      type="confirm"
      @confirm="submitCopyTemplate"
    >
      <template v-slot:content>
        <div v-if="curTemplate">
          テンプレート名：<b>{{ curTemplate.name }}</b>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Copy template modal -->
    <modal-template-preview :templateId="curTemplate.id" v-if="curTemplate"></modal-template-preview>
  </div>
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      isPc: true,
      selectedFolderIndex: 0,
      curTemplateIndex: null,
      loading: true,
      currentPage: 1,
      contentKey: 0,
      templatesCopy: [],
      queryParams: null
    };
  },

  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },

  async beforeMount() {
    await this.getFolders({ is_filter_draft: true });
    const folderId = Number.parseInt(Util.getParamFromUrl('folder_id'));
    const index = folderId > 0 ? _.findIndex(this.folders, _ => _.id === folderId) : 0;
    this.onSelectedFolderChanged(index > 0 ? index : 0);
    this.loading = false;
  },

  computed: {
    ...mapGetters('template', ['getQueryParams']),
    ...mapState('template', {
      folders: state => state.folders,
      templates: state => state.templates,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curFolder() {
      return this.folders[this.selectedFolderIndex];
    },

    curTemplate() {
      return this.curFolder ? this.templates[this.curTemplateIndex] : null;
    }
  },

  methods: {
    ...mapMutations('template', ['setQueryParams']),
    ...mapActions('template', [
      'getTemplates',
      'deleteTemplate',
      'copyTemplate',
      'deleteFolder',
      'createFolder',
      'updateFolder',
      'getFolders',
      'reorderFolders',
      'reorderTemplates'
    ]),

    async onDragFolderEnd(payload) {
      if (payload.ordered_ids.length < 2) return;
      await this.reorderFolders(payload);
    },

    async moveTemplateUp(index) {
      if (index <= 0) return;

      const id = this.templatesCopy[index].id;

      await this.reorderTemplates({
        action_type: 'move',
        id,
        direction: 'up'
      });

      const item = this.templatesCopy.splice(index, 1)[0];
      this.templatesCopy.splice(index - 1, 0, item);
    },

    async moveTemplateDown(index) {
      if (index >= this.templatesCopy.length - 1) return;

      const id = this.templatesCopy[index].id;

      await this.reorderTemplates({
        action_type: 'move',
        id,
        direction: 'down'
      });

      const item = this.templatesCopy.splice(index, 1)[0];
      this.templatesCopy.splice(index + 1, 0, item);
    },

    async onDragTemplateEnd(event) {
      const { oldIndex, newIndex } = event;
      if (oldIndex === newIndex) return;

      const limitedTemplates = Util.getLimitedRange(this.templatesCopy, oldIndex, newIndex);
      const orderedIds = limitedTemplates.map(template => template.id);

      await this.reorderTemplates({
        action_type: 'drag_drop',
        ordered_ids: orderedIds
      });

      await this.getTemplates({ show_draft: true });
    },

    forceRerender() {
      this.contentKey++;
    },

    onSelectedFolderChanged(index) {
      this.selectedFolderIndex = index;
      this.queryParams.page = 1;
      this.isPc = true;

      if (this.queryParams.folder_id_eq !== this.curFolder.id) {
        this.queryParams.folder_id_eq = this.curFolder.id;
        this.loadPage();
      }
    },

    async submitCreateFolder(folder) {
      const response = await this.createFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの作成は完了しました。');
      } else {
        window.toastr.error('フォルダーの作成は失敗しました。');
      }
    },

    async submitUpdateFolder(folder) {
      const response = await this.updateFolder(folder);
      if (response) {
        window.toastr.success('フォルダーの変更は完了しました。');
      } else {
        window.toastr.error('フォルダーの変更は失敗しました。');
      }
    },

    async submitDeleteFolder() {
      const response = await this.deleteFolder(this.curFolder.id);
      if (response) {
        window.toastr.success('フォルダーの削除は完了しました。');
        this.onSelectedFolderChanged(0);
      } else {
        window.toastr.error('フォルダーの削除は失敗しました。');
      }
    },

    async submitDeleteTemplate() {
      const response = await this.deleteTemplate(this.curTemplate.id);
      const url = `${this.rootPath}/user/templates?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('テンプレートの削除は完了しました。', url);
      } else {
        window.toastr.error('テンプレートの削除は失敗しました。');
      }
      this.forceRerender();
    },

    async submitCopyTemplate() {
      const response = await this.copyTemplate(this.curTemplate.id);
      const url = `${this.rootPath}/user/templates?folder_id=${this.curFolder.id}`;
      if (response) {
        Util.showSuccessThenRedirect('テンプレートのコピーは完了しました。', url);
      } else {
        Util.showSuccessThenRedirect('テンプレートのコピーは失敗しました。', url);
      }
      this.forceRerender();
    },

    backToFolder() {
      this.isPc = false;
    },

    formattedDate(date) {
      return Util.formattedDate(date);
    },

    async loadPage() {
      this.$nextTick(async() => {
        this.loading = true;
        this.setQueryParams(this.queryParams);
        await this.getTemplates({ show_draft: true });
        this.templatesCopy = this.templates;
        this.forceRerender();
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="scss"  scoped>
  ::v-deep {
    td .emojione {
      width: 20px !important;
    }

    td .chat-item > .sticker-static {
      width: 50px !important;
    }

    td .chat-item {
      padding: 0px;
    }
    .template-message-count-wrapper {
      gap: 0 6px;

      .template-message-count {
        margin-top: 1px;
      }
    }
    .dragging-item {
      opacity: 0.8;
      border: 2px solid #008a00 !important;
    }
    .destination-item {
      opacity: 0.5;
      border: 2px dashed #6a0dad;
      background-color: #d8bfd8;
    }
  }
</style>
