import { render, staticRenderFns } from "./ReviewIndex.vue?vue&type=template&id=7bce4ec0&scoped=true"
import script from "./ReviewIndex.vue?vue&type=script&lang=js"
export * from "./ReviewIndex.vue?vue&type=script&lang=js"
import style0 from "./ReviewIndex.vue?vue&type=style&index=0&id=7bce4ec0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bce4ec0",
  null
  
)

export default component.exports