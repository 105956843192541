export function convertKeysToSnakeCase(obj) {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    newObj[newKey] = obj[key];
  });

  return newObj;
}
