<template>
  <div>
    <!-- <action-postback :showTitle="showTitle" v-bind:value="data" v-if="data.type === 'postback'" @input="changeAction" :type="type" :name="name" :requiredLabel="requiredLabel"/> -->
    <action-send-message
      :showTitle="showTitle"
      v-bind:value="data"
      v-if="data.type === 'message'"
      @input="changeAction"
      :name="name"
      :requiredLabel="requiredLabel"
    ></action-send-message>

    <action-open-url
      :showTitle="showTitle"
      v-bind:value="data"
      v-if="data.type === 'uri' && regexUrl"
      @input="changeAction"
      :name="name"
      :requiredLabel="requiredLabel"
    ></action-open-url>

    <action-open-tel
      :showTitle="showTitle"
      v-bind:value="data"
      v-if="data.type === 'uri' && regexUri"
      @input="changeAction"
      :name="name"
      :requiredLabel="requiredLabel"
    ></action-open-tel>

    <action-datetime-picker
      :showTitle="showTitle"
      v-bind:value="data"
      v-if="data.type === 'datetimepicker'"
      @input="changeAction"
      :name="name"
      :requiredLabel="requiredLabel"
    ></action-datetime-picker>

    <action-survey
      :showTitle="showTitle"
      v-bind:value="data"
      v-if="data.type === 'survey'"
      @input="changeAction"
      :name="name"
      :requiredLabel="requiredLabel"
    ></action-survey>

    <action-default
      :showTitle="showTitle"
      v-bind:value="data"
      v-if="data.type === 'camera' || data.type === 'cameraRoll' || data.type === 'location'"
      @input="changeAction"
      :name="name"
      :requiredLabel="requiredLabel"
    ></action-default>
  </div>
</template>
<script>
import Util from '../../../core/util';

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true
    },
    data: Object,
    name: String,
    type: String,
    requiredLabel: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    regexUrl() {
      return this.data.id ? this.data.id === 1 : Util.validateUrl(this.data.uri);
    },

    regexUri() {
      return this.data.id ? this.data.id === 2 : !Util.validateUrl(this.data.uri);
    }
  },
  methods: {
    changeAction(value) {
      this.$emit('input', value);
    }
  }
};
</script>
