import { render, staticRenderFns } from "./AccountPasswordEdit.vue?vue&type=template&id=cbe7ece2"
import script from "./AccountPasswordEdit.vue?vue&type=script&lang=js"
export * from "./AccountPasswordEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports