<template>
  <section>
    <label class="w-100">
      コンテンツ
      <required-mark />
    </label>

    <div>
      <div
        data-toggle="modal"
        :data-target="'#' + name"
        class="btn btn-secondary mw-150"
        v-if="currentTemplate.template_id"
      >
        <message-content :data="currentTemplate" v-if="currentTemplate.content" />
        <span v-else>{{ currentTemplate.name }}</span>
      </div>
      <div data-toggle="modal" :data-target="'#' + name" class="btn btn-secondary mw-150" v-else>
        テンプレートを選択
      </div>
      <input
        type="hidden"
        v-model="currentTemplate.template_id"
        :name="name + '_template_id'"
        v-validate="'required'"
        data-vv-as="テンプレート"
      />
      <error-message class="w-100" :message="errors.first(name + '_template_id')"></error-message>
    </div>

    <modal-select-template @selectTemplate="selectTemplate" :id="name" />
  </section>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          template_id: null,
          name: 'テンプレートから作成'
        };
      }
    },
    name: {
      type: String,
      default: 'postback_action'
    }
  },
  inject: ['parentValidator'],

  data() {
    return {
      currentTemplate: {
        template_id: null,
        name: null
      }
    };
  },

  created() {
    this.$validator = this.parentValidator;

    if (this.value.template_id) {
      this.currentTemplate = {
        template_id: this.value.template_id,
        name: this.value.name
      };
    }
  },

  watch: {
    currentTemplate: {
      handler(val) {
        if (this.value.template_id) {
          this.$emit('input', {
            name: val.name,
            template_id: this.value.template_id
          });
        }
      },
      deep: true
    }
  },

  methods: {
    selectTemplate(template) {
      this.currentTemplate = { template_id: template.id, ...template };
      this.$emit('input', {
        name: template.name,
        template_id: template.id
      });
    }
  }
};
</script>

<style scoped>
  .btn-block {
    cursor: pointer;
  }
</style>
