<template>
  <div>
    <div class="card">
      <div class="card-header"><h4>パースワード変更</h4></div>
      <ValidationObserver ref="observer" v-slot="{ validate, invalid }">
        <form @submit.prevent="validate().then(onUpdatePassword)" class="card-body">
          <div class="form-group row">
            <label class="col-xl-3">現在のパスワード<required-mark /></label>
            <div class="col-xl-9">
              <ValidationProvider name="現在のパスワード" rules="required|min:8|max:128" v-slot="{ errors }">
                <input
                    type="password"
                    class="form-control"
                    name="user[current_password]"
                    placeholder="入力してください"
                    maxlength="128"
                    v-model.trim="userFormData.current_password"
                />
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3">パスワード<required-mark /></label>
            <div class="col-xl-9">
              <ValidationProvider
                  name="パスワード"
                  rules="required|min:8|max:128"
                  v-slot="{ errors }"
                  vid="password"
              >
                <input
                    type="password"
                    class="form-control"
                    name="user[password]"
                    placeholder="入力してください"
                    maxlength="128"
                    v-model.trim="userFormData.password"
                />
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3">パスワード（確認用）<required-mark /></label>
            <div class="col-xl-9">
              <ValidationProvider
                  name="パスワード（確認用）"
                  rules="required|min:8|max:128|confirmed:password"
                  v-slot="{ errors }"
              >
                <input
                    type="password"
                    class="form-control"
                    name="user[password_confirmation]"
                    placeholder="入力してください"
                    maxlength="128"
                    v-model.trim="userFormData.password_confirmation"
                />
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="card-footer row-form-btn d-flex">
            <button type="submit" class="btn btn-info fw-120" :disabled="invalid">
              変更
            </button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Util from '@/core/util';

export default {
  props: ['user'],
  data() {
    return {
      rootUrl: process.env.MIX_ROOT_PATH,
      userFormData: {
        id: this.user.id,
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    };
  },

  computed: {
    userListUrl() {
      return `${this.rootUrl}/user/profile/edit`;
    }
  },

  methods: {
    ...mapActions('user', ['updateUserProfile']),

    onUpdatePassword() {
      this.updateUserProfile(this.userFormData)
        .then(() => {
          Util.showSuccessThenRedirect('パースワードの変更は完了しました。', this.userListUrl);
        })
        .catch(error => {
          window.toastr.error(error.responseJSON.message);
        });
    }
  }
};
</script>
