<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ validate, invalid }">
      <!-- LINE公式アカウント連携設定 -->
      <div class="card">
        <div class="card-body">
          <div class="form-group row">
            <label class="col-4">管理用の名前（自由に記載してください)<required-mark></required-mark></label>
            <div class="col-8">
              <ValidationProvider name="管理用の名前" rules="required|max:255" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  placeholder="入力してください"
                  name="bot[line_name]"
                  maxlength="256"
                  v-validate="'required|max:255'"
                  v-model.trim="botForm.line_name"
                />
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-4">LINE公式アカウントID<required-mark></required-mark></label>
            <div class="col-8">
              <ValidationProvider name="LINE公式アカウントID" rules="required|max:255" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  placeholder="入力してください"
                  name="bot[line_user_id]"
                  maxlength="256"
                  v-validate="'required|max:255'"
                  v-model.trim="botForm.line_user_id"
                />
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-4">LIFF ID</label>
            <div class="col-8">
              <ValidationProvider name="LIFF ID" rules="max:255" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  placeholder="入力してください"
                  name="bot[liff_id]"
                  maxlength="256"
                  v-validate="'max:255'"
                  v-model.trim="botForm.liff_id"
                />
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-4">チャネルID<required-mark></required-mark></label>
            <div class="col-8">
              <ValidationProvider name="チャネルID" rules="required|max:255" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  placeholder="入力してください"
                  name="bot[channel_id]"
                  maxlength="256"
                  v-validate="'required|max:255'"
                  v-model.trim="botForm.channel_id"
                />
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-4">チャネルシークレット<required-mark></required-mark></label>
            <div class="col-8">
              <ValidationProvider name="チャネルシークレット" rules="required|max:255" v-slot="{ errors }">
                <input
                  type="text"
                  class="form-control"
                  placeholder="入力してください"
                  name="bot[channel_secret]"
                  maxlength="256"
                  v-validate="'required|max:255'"
                  v-model.trim="botForm.channel_secret"
                />
                <span class="error-explanation">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="form-group row mt-4">
            <label class="col-4">Webhook URL</label>
            <div class="col-8">
              <input-with-copy :text="webhookUrl"></input-with-copy>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="btn btn-info fw-120" :disabled="invalid" @click="validate().then(onSubmit)">保存</div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Util from '@/core/util';

export default {
  props: ['bot'],

  data() {
    return {
      rootPath: process.env.MIX_ROOT_PATH,
      submitted: false,
      botForm: {
        line_name: null,
        line_user_id: null,
        channel_id: null,
        channel_secret: null,
        liff_id: null
      }
    };
  },

  beforeMount() {
    Object.assign(this.botForm, this.bot);
  },

  computed: {
    webhookUrl() {
      return `${this.rootPath}/webhooks/${this.bot.webhook_url}`;
    }
  },

  methods: {
    ...mapActions('bot', ['createBot', 'updateBot']),

    onSubmit(e) {
      this.submitted = true;
      if (this.bot.id) {
        this.updateBot(this.botForm)
          .then(response => {
            this.onReceiveUpdateBotResponse(response.id, null);
          })
          .catch(error => {
            this.onReceiveUpdateBotResponse(null, error.responseJSON.message);
          });
      } else {
        this.createBot(this.botForm)
          .then(response => {
            this.onReceiveCreateBotResponse(response.id, null);
          })
          .catch(error => {
            this.onReceiveCreateBotResponse(null, error.responseJSON.message);
          });
      }
    },
    onReceiveCreateBotResponse(id, errorMessage) {
      if (id) {
        Util.showSuccessThenRedirect('LINE公式アカウントの登録は完了しました。', `${this.rootPath}/user/bots`);
      } else {
        window.toastr.error(errorMessage);
      }
    },
    onReceiveUpdateBotResponse(id, errorMessage) {
      if (id) {
        Util.showSuccessThenRedirect('LINE公式アカウントの変更は完了しました。', `${this.rootPath}/user/bots`);
      } else {
        window.toastr.error(errorMessage);
      }
    }
  }
};
</script>
