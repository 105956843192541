<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header d-flex align-items-center">
            <a :href="`${rootUrl}/user/bots/new`" class="btn btn-info fw-120 mr-2">
              <i class="uil-plus"></i> 新規登録
            </a>
            <!-- START: Search form -->

            <div class="ml-auto d-flex">
              <div class="input-group app-search">
                <input
                  type="text"
                  class="form-control dropdown-toggle fw-250"
                  placeholder="検索..."
                  v-model="queryParams.line_name_cont"
                />
                <span class="mdi mdi-magnify search-icon"></span>
                <div class="input-group-append">
                  <div class="btn btn-info" @click="loadBots">検索</div>
                </div>
              </div>
            </div>
            <!-- End: Search form -->
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered mb-0">
                <thead class="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>管理用の名前</th>
                    <th>LINE公式アカウントID</th>
                    <th>チャネルID</th>
                    <th>Webhook URL</th>
                    <th class="fw-200">操作</th>
                  </tr>
                </thead>
                <tbody v-for="(bot) in bots" :key="bot.id">
                  <tr>
                    <td class="fw-100">
                      <span>{{ bot.id }}</span>
                    </td>
                    <td class="mxw-200">{{ bot.line_name }}</td>
                    <td class="mxw-150">{{ bot.line_user_id }}</td>
                    <td class="mxw-150">{{ bot.channel_id }}</td>
                    <td class="mxw-300"><input-with-copy :text="webhookUrl(bot.webhook_url)"></input-with-copy></td>
                    <td class="fw-200">
                      <div class="btn-group">
                        <button
                          type="button"
                          class="btn btn-light btn-sm dropdown-toggle"
                          id="dropdownMenuUser"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          操作 <span class="caret"></span>
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuUser">
                          <a
                            :href="`${rootUrl}/user/bots/${bot.id}/edit`"
                            role="button"
                            class="dropdown-item"
                            >編集</a
                          >
                          <!-- <a
                            role="button"
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#modalDeleteUser"
                            @click="curUserIndex = index"
                            >削除</a
                          > -->
                        </div>
                        <a :href="`${rootUrl}/user/bots/${bot.id}/sso`" class="btn btn-sm btn-info">作業へ</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <b-pagination
                v-if="totalRows > perPage"
                v-model="queryParams.page"
                :total-rows="totalRows"
                :per-page="perPage"
                @change="loadBots"
                aria-controls="my-table"
              ></b-pagination>
              <div class="my-5 font-weight-bold text-center" v-if="!loading && totalRows === 0">
                データはありません。
              </div>
            </div>
          </div>
          <loading-indicator :loading="loading"></loading-indicator>
        </div>
      </div>
    </div>
    <!-- START: Delete bot modal -->
    <modal-confirm
      title="こちらのLINE公式アカウントを削除してよろしいですが?"
      id="modalDeleteUser"
      type="delete"
      @confirm="submitDeleteBot"
    >
      <template v-slot:content>
        <div v-if="curBot">
          管理用の名前: <b>{{ curBot.line_name | truncate(64) }}</b>
        </div>
      </template>
    </modal-confirm>
    <!-- END: Delete bot modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Util from '@/core/util';

export default {
  data() {
    return {
      rootUrl: process.env.MIX_ROOT_PATH,
      currentPage: 1,
      contentKey: 0,
      loading: true,
      curBotIndex: 0,
      isSearch: false,
      queryParams: null
    };
  },
  created() {
    this.queryParams = _.cloneDeep(this.getQueryParams);
  },
  async beforeMount() {
    await this.getBots();
    this.loading = false;
  },

  computed: {
    ...mapGetters('bot', ['getQueryParams']),
    ...mapState('bot', {
      bots: state => state.bots,
      totalRows: state => state.totalRows,
      perPage: state => state.perPage
    }),

    curBot() {
      return this.bots[this.curBotIndex];
    }
  },
  methods: {
    ...mapMutations('bot', ['setCurPage', 'setQueryParams']),
    ...mapActions('bot', ['getBots', 'deleteBot', 'searchUsers']),

    forceRerender() {
      this.contentKey++;
    },

    async loadBots() {
      this.$nextTick(async() => {
        this.setQueryParams(this.queryParams);
        this.loading = true;
        this.getBots();
        this.forceRerender();
        this.loading = false;
      });
    },

    async submitDeleteBot() {
      const response = await this.deleteBot(this.curBot.id);
      if (response) {
        Util.showSuccessThenRedirect('LINE公式アカウントの削除は完了しました。', `${this.rootUrl}/user/bots`);
      } else window.toastr.error('LINE公式アカウントの削除は失敗しました。');
    },

    webhookUrl(webhookUrl) {
      return `${this.rootUrl}/webhooks/${webhookUrl}`;
    }
  }
};
</script>
