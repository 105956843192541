import BotAPI from '../api/bot_api';

export const state = {
  bots: [],
  totalRows: 0,
  perPage: 0,
  queryParams: {
    page: 1,
    line_name_cont: ''
  }
};

export const mutations = {
  setBots(state, bots) {
    state.bots = bots;
  },

  setMeta(state, meta) {
    state.totalRows = meta.total_count;
    state.perPage = meta.limit_value;
    state.curPage = meta.current_page;
  },

  setQueryParams(state, params) {
    Object.assign(state.queryParams, params);
  },

  setQueryParam(state, param) {
    Object.assign(state.queryParams, param);
  }
};

export const getters = {
  getQueryParams(state) {
    return state.queryParams;
  }
};

export const actions = {
  createBot(_, query) {
    return BotAPI.create(query).done((res) => {
      return Promise.resolve(res);
    }).fail((err) => {
      return Promise.reject(err);
    });
  },

  updateBot(_, query) {
    return BotAPI.update(query).done((res) => {
      return Promise.resolve(res);
    }).fail((err) => {
      return Promise.reject(err);
    });
  },

  async getBots(context) {
    try {
      const params = {
        page: state.queryParams.page,
        q: _.omit(state.queryParams, 'page')
      };
      const response = await BotAPI.list(params);
      context.commit('setBots', response.data);
      context.commit('setMeta', response.meta);
      return response;
    } catch (error) {
      console.log(error);
      return null;
    }
  },

  async deleteBot(context, id) {
    try {
      return await BotAPI.delete(id);
    } catch (error) {
      return null;
    }
  }
};
